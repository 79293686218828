import axios from 'axios';

const baseUrl = process.env.VUE_APP_PROD_URL;

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default class Api {
  static async getAll(params) {
    try {
      const response = await api.get('/projects', { params });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getOne(id) {
    try {
      const response = await api.get(`/projects/${id}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async sendMail(values) {
    try {
      const response = await api.post('/emails', values);
      return response.status;
    } catch (err) {
      console.log(err);
    }
  }

  static getUrl(type, file) {
    return `${baseUrl}/${type}/${file}`;
  }
}
