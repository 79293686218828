<template>
  <app-animation v-if="showAnim" />
  <template v-else>
    <app-navbar />
    <div class="content-wrapper">
      <router-view />
    </div>
    <email-side />
    <social-side />
    <app-footer />
    <app-cookies />
    <scroll-top />
  </template>
</template>

<script>
import AppAnimation from '@/components/app/Animation';
import AppNavbar from '@/components/app/Navbar';
import AppCookies from '@/components/app/Cookies';
import EmailSide from '@/components/sides/EmailSide';
import SocialSide from '@/components/sides/SocialSide';
import AppFooter from '@/components/app/Footer';
import Session from '@/utils/services/Session';
import ScrollTop from '@/components/app/ScrollTop';

export default {
  name: 'App',
  components: {
    AppNavbar,
    EmailSide,
    SocialSide,
    AppFooter,
    AppAnimation,
    ScrollTop,
    AppCookies,
  },
  data() {
    return {
      showAnim: true,
    };
  },
  created() {
    setTimeout(() => {
      this.showAnim = false;
    }, 1500);
  },
  watch: {
    $route(_to, from) {
      Session.setFromRoute(from.name, from.path);
    },
  },
};
</script>

<style lang="scss">
.content-wrapper {
  padding: $sp-5x $sp-6x $sp-2x $sp-6x;
  margin: 0 auto;
  width: 95%;
  max-width: 1600px;
  min-height: 90vh !important;
}

@include bp-down(lg) {
  .content-wrapper {
    padding: $sp-6x 0.5rem 0 0.5rem;
  }
}
</style>
