<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 76.000000 76.000000"
    preserveAspectRatio="xMidYMid meet"
    class="svg-inline--fa fa-w-14"
    role="img"
    alt="Malt Icon"
  >
    <g
      transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M81 747 c-19 -7 -44 -25 -55 -40 -20 -27 -21 -40 -21 -327 0 -287 1
-300 21 -327 35 -47 66 -52 354 -52 288 0 319 5 354 52 20 27 21 40 21 327 0
287 -1 300 -21 327 -11 15 -36 33 -55 40 -47 16 -551 16 -598 0z m347 -124
c18 -25 15 -36 -21 -71 l-33 -32 -33 34 c-25 26 -31 39 -26 56 13 40 85 49
113 13z m-118 -71 c64 -56 53 -72 -49 -72 -63 0 -80 3 -85 16 -11 28 -6 47 16
71 32 34 68 29 118 -15z m250 18 c43 -43 31 -63 -138 -232 -102 -103 -165
-158 -178 -158 -36 0 -74 38 -74 72 0 27 22 53 153 185 134 134 156 153 185
153 19 0 40 -8 52 -20z m-260 -118 c0 -4 -29 -36 -64 -71 -68 -68 -82 -71
-111 -27 -21 32 -9 76 26 93 26 14 149 18 149 5z m311 -12 c26 -14 26 -80 0
-104 -15 -13 -34 -16 -92 -14 l-74 3 59 63 c60 64 73 70 107 52z m-33 -186 c2
-25 -3 -37 -24 -53 -39 -30 -60 -26 -111 22 -61 58 -53 69 50 65 l82 -3 3 -31z
m-138 -78 c0 -40 -83 -62 -114 -30 -24 23 -20 41 17 73 l32 30 33 -32 c17 -17
32 -35 32 -41z"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: 'MaltIcon',
    props: ['width', 'height']
};
</script>