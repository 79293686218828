<template v-if="showContent">
  <home-header />
  <home-about />
  <home-featured />
  <home-projects />
  <home-contact />
</template>

<script>
import HomeHeader from '@/components/home/HomeHeader';
import HomeAbout from '@/components/home/HomeAbout';
import HomeFeatured from '@/components/home/HomeFeatured';
import HomeProjects from '@/components/home/HomeProjects';
import HomeContact from '@/components/home/HomeContact';

export default {
  name: 'Home',
  components: {
    HomeHeader,
    HomeAbout,
    HomeFeatured,
    HomeProjects,
    HomeContact,
  },
  data() {
    return {
      showContent: false,
    };
  },
  created() {
    setTimeout(() => {
      this.showContent = true;
    }, 500);
  },
};
</script>
