<template>
  <div class="side side-left slide-up-d0">
    <div class="side-content">
      <a href="https://www.linkedin.com/in/alison-vandromme/" class="icon-link" aria-label="Linkedin" target="_blank"
        ><span>Linkedin</span><i class="bi bi-linkedin"></i
      ></a> 
      <a href="https://github.com/AlisonV2'" class="icon-link" aria-label="Github" target="_blank"
        ><span>Github</span><i class="bi bi-github"></i
      ></a>
      <a href="https://alisonv2.medium.com/" class="icon-link" aria-label="Medium" target="_blank"
        ><span>Medium</span><i class="bi bi-medium"></i
      ></a>
      <a href="https://www.malt.fr/profile/alisonvandromme1" class="icon-link" aria-label="Malt" target="_blank"
        ><span>Malt</span><malt-icon width="20pt" height="20pt"/>
       </a>
    </div>
  </div>
</template>

<script>
import MaltIcon from '@/components/app/MaltIcon'
export default {
  name: 'Social',
  components: {
    MaltIcon,
  },
};
</script>
