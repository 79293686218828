<template>
  <div class="row home-header">
    <div class="col-12">
      <p class="txt-primary mb-0 slide-down-d0">Hi, my name is</p>
      <h1 class="slide-down-d0">Alison Vandromme.</h1>
      <h2 class="txt-muted slide-left-d0">I build things for the web.</h2>
      <p class="description slide-left-d0">
        I'm a fullstack web developer specializing in building (and sometimes
        designing) exceptional digital experiences. Currently, I'm focused on
        making Circular Economy and Life Cycle Assessment accessible to a wider
        audience at
        <a
          href="https://weloop.org/en/"
          class="inline-link"
          target="_blank"
          rel="noreferrer"
          >WeLOOP</a
        >.
      </p>
      <router-link :to="{ name: 'Projects'}" class="app-btn slide-left-d0">See my work</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeHeader',
};
</script>

<style lang="scss">
.home-header {
  min-height: 60vh !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    margin-bottom: $sp;
  }
  .description {
    max-width: 60%;
    margin-bottom: $sp-2x;
  }
  .app-btn {
    font-size: 20px !important;
  }
}

@include bp-down(lg) {
  .home-header {
    text-align: center;
    padding-bottom: $sp-2x;
    .description {
      max-width: 100%;
    }
  }
}
</style>
