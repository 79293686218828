<template>
  <div class="animation05">
    <div class="double_wrapper02 green02">
      <div class="double_wrapper01 green01">
        <div class="double_block green00"></div>
      </div>
    </div>
    <div class="double_wrapper02 navy02">
      <div class="double_wrapper01 navy01">
        <div class="double_block navy00"></div>
      </div>
    </div>
    <div class="double_wrapper02 yellow02">
      <div class="double_wrapper01 yellow01">
        <div class="double_block yellow00"></div>
      </div>
    </div>
    <div class="double_wrapper02 blue02">
      <div class="double_wrapper01 blue01">
        <div class="double_block blue00"></div>
      </div>
    </div>
    <div class="double_wrapper02 red02">
      <div class="double_wrapper01 red01">
        <div class="double_block red00"></div>
      </div>
    </div>
  </div>
  <div class="animation06">
    <div class="rhombus05">
      <div class="rhombus04">
        <div class="rhombus03">
          <div class="rhombus02">
            <div class="rhombus01"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.animation06 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: $darker;
}

.rhombus05 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: rhombus 1.2s ease-in forwards;
  opacity: 0;
}

.rhombus04 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 50vw;
  height: 50vw;
  background-color: $secondary;
}

.rhombus03 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37.5vw;
  height: 37.5vw;
  background-color: $primary;
}

.rhombus02 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 25vw;
  background-color: $light;
}

.rhombus01 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12.25vw;
  height: 12.25vw;
  background-color: $darker;
}

@keyframes rhombus {
  0% {
    transform: scale3d(0, 0, 1);
    opacity: 1;
  }
  100% {
    transform: scale3d(10, 10, 1);
    opacity: 1;
  }
}

@media screen and (max-width: 750px) {
  @keyframes rhombus {
    0% {
      transform: scale3d(0, 0, 1);
      opacity: 1;
    }
    100% {
      transform: scale3d(20, 20, 1);
      opacity: 1;
    }
  }
}
</style>
