<template>
  <div class="cookie-banner slide-up-d0" v-if="showBanner">
    <div class="cookie-banner__content">
      <p class="cookie-banner__text mb-4">
        This website uses cookies to enhance your browsing experience and
        analyze the traffic.
      </p>
      <button class="cookie-banner__button" @click="setCookies">
        I understand
      </button>
    </div>
  </div>
</template>

<script>
// TODO: Option to decline

export default {
  name: 'AppCookies',
  data() {
    return {
      showBanner: false,
      cookie: {
        consent: true,
        date: new Date().toString(),
      },
    };
  },
  created() {
    const cookie = this.$cookies.get('user_consent');

    if (!cookie) this.showBanner = true;
  },
  methods: {
    setAnalytics() {
      this.$cookies.set('user_consent', JSON.stringify(this.cookie), '30d');
      this.$matomo.rememberCookieConsentGiven();
    },
    setCookies() {
      this.setAnalytics();
      this.showBanner = false;
    },
  },
};
</script>

<style lang="scss">
.cookie-banner {
  position: fixed;
  bottom: 2rem;
  left: 1rem;
  width: 400px;
  background-color: $dark;
  color: #fff;
  padding: 1rem;
  z-index: 1000;
  box-shadow: $shadow-default;
  border-radius: 2px;
  font-size: 16px;
  p {
    font-weight: 300;
  }
  button {
    @include button(16px, 0.2rem, 1rem, $primary, $light);
  }
}

@include bp-down(lg) {
  .cookie-banner {
    left: 4%;
    width: 92%;
    bottom: 2%;
  }
}
</style>
