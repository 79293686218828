<template>
  <div class="row" :id="id">
    <div
      class="col-12 section-title fade-in-long"
      :class="{ active: isActive }"
    >
      <h2>{{ title }}</h2>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
  props: ['title', 'id'],
  emits: ['activated', 'deactivated'],
  data() {
    return {
      isActive: false,
    };
  },
  created() {
    window.addEventListener('scroll', () => {
      const el = document.getElementById(this.id);
      if (el) {
        const position = el.getBoundingClientRect();
        if (position.top >= 150 && position.top <= 500) {
          this.isActive = true;
          this.$emit('activated');
        } else {
          this.isActive = false;
          this.$emit('deactivated');
        }
      }
    });
  },
};
</script>
