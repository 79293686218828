<template>
  <div class="spacer-2x"></div>
  <AppSection title="Contact" id="contact">
    <div class="col-12 mb-4">
      <p class="description">
        My inbox is always open whether you have a question or want to say hi.
        <br />
        I will get back to you as soon as possible!
      </p>
      <contact-form text="Get in touch" />
    </div>
  </AppSection>
</template>

<script>
import AppSection from '@/components/app/Section';
import ContactForm from '@/components/contact/Form';

export default {
  name: 'Contact',
  components: {
    AppSection,
    ContactForm
  },
};
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: $sp-2x;
}
</style>
