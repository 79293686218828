<template>
  <app-section
    title="About me"
    id="about"
    @activated="isActive = true"
    @deactivated="isActive = false"
  >
    <div class="row mb-4 about fade-in-long">
      <div class="col-12 col-lg-7 mb-4">
        <p>
          My name is Alison, and I enjoy creating things that live on the
          internet.<br />
        </p>
        <p>
          Chief Technical Officer at
          <a
            href="https://weloop.org"
            target="_blank"
            rel="noreferrer"
            class="inline-link"
            >WeLOOP</a
          >, I have the privilege of working on many projects for various
          clients. Each project is significantly different from the next one,
          allowing me to broaden my knowledge and skills. However, they all have
          in common the focus on building accessible and inclusive products
          conceived in a user-first mindset.
        </p>
        <p>
          These projects aim to make Life Cycle Assessment and environmental
          issues accessible to a broader spectrum. In keeping with this theme, I
          take an eco-design approach to web services conception.
        </p>
        <div class="btn-div">
          <router-link :to="{ name: 'About' }">
            <button class="app-btn mt-4">Get to know me</button></router-link
          >
        </div>
      </div>
      <div class="col-12 col-lg-5 about-img mb-4" :class="{ active: isActive }">
        <div class="img-bg" :class="{ active: isActive }"></div>
        <img
          class="img-fluid"
          :src="imgUrl"
          alt="Alison Vandromme Photo"
        />
      </div>
    </div>
  </app-section>
</template>

<script>
import AppSection from '@/components/app/Section';
import Api from '@/utils/services/Api';

export default {
  name: 'HomeAbout',
  components: {
    AppSection,
  },
  data() {
    return {
      isActive: false,
      imgUrl: ''
    };
  },
  created() {
    this.imgUrl = Api.getUrl('images', 'avandromme.jpg')
  }
};
</script>
