<template>
  <div class="alert" :class="`alert-${alert.type}`" v-if="alert.show">
    <i
      class="bi"
      :class="alert.type === 'success' ? 'bi-check-circle' : 'bi-x-circle'"
    ></i>
    <p>
      {{
        alert.type === 'success'
          ? 'Email sent successfully'
          : 'There was a problem sending the email.'
      }}
    </p>
  </div>
  <div class="mt-4 contact-btn">
    <button class="app-btn" @click="showModal = true">{{ text }}</button>
  </div>
  <div class="modal-wrapper" :class="{ visible: showModal }">
    <div class="modal-body form-modal">
      <div class="modal-header">
        <h3 class="txt-primary mb-4">Contact me</h3>
        <a
          role="button"
          class="close"
          aria-label="Close"
          @click="showModal = false"
        >
          <i class="bi bi-x"></i>
        </a>
      </div>
      <div class="row">
        <div class="col-12 txt-col">
          <form>
            <div class="text-center">
              <p v-if="showErr" class="txt-danger">Please fill in all fields</p>
            </div>
            <div
              v-for="item in form"
              class="form-item"
              :class="item.value.length > 0 ? 'has-value' : ''"
            >
              <component
                :is="item.component"
                :type="item.type"
                :id="item.id"
                class="form-control"
                @input="(e) => (item.value = e.target.value)"
                :value="item.value"
              />
              <label :for="item.id">{{ item.label }}</label>
            </div>
          </form>
        </div>
      </div>
      <div class="btn-right mb-4">
        <button class="app-btn" @click.prevent="sendEmail">Send</button>
      </div>
    </div>
    <span class="outside-trigger" @click="showModal = false"></span>
  </div>
</template>

<script>
import Api from '@/utils/services/Api';
export default {
  name: 'ContactForm',
  props: ['text'],
  data() {
    return {
      showModal: false,
      showErr: false,
      alert: {
        show: false,
        type: 'danger',
      },
      form: [
        {
          label: 'Name',
          component: 'input',
          type: 'text',
          id: 'name',
          value: '',
          required: true,
        },
        {
          label: 'Email',
          component: 'input',
          type: 'email',
          id: 'email',
          value: '',
          required: true,
        },
        {
          label: 'Message',
          component: 'textarea',
          type: 'text',
          id: 'message',
          value: '',
          required: true,
        },
      ],
    };
  },
  watch: {
    show() {
      this.showModal = this.show;
    },
  },
  methods: {
    async sendEmail() {
      this.validate();
      let values = {};

      if (!this.showErr) {
        this.form.forEach((item) => {
          values[item.id] = item.value;
        });

        const status = await Api.sendMail(values);

        if (status === 200) {
          this.alert = {
            show: true,
            type: 'success',
          };

          this.showModal = false;

          this.form.forEach((item) => {
            item.value = '';
          });
        } else {
          this.alert = {
            show: true,
            type: 'danger',
          };
        }

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }
    },
    validate() {
      this.showErr = false;
      this.form.forEach((item) => {
        if (item.required && item.value.length === 0) this.showErr = true;
      });
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style lang="scss">
.contact-btn {
  @include bp-down(lg) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
