import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueMatomo from 'vue-matomo';
import VueCookies from 'vue3-cookies';
import matomo from './utils/matomo';
import './assets/styles/app/app.scss';
import './registerServiceWorker';

const app = createApp(App);
app
  .use(router)
  .use(VueCookies)
  .use(VueMatomo, {
    ...matomo,
    router: router,
  })
  .mount('#app');
