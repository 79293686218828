<template>
  <div class="row mt-4 text-center footer">
    <div class="col-12">
      <p
        >Copyright {{ new Date().getFullYear() }} - Alison Vandromme
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style lang="scss">
.footer {
  padding: $sp 0rem;
  p {
      font-size: 0.8rem;
      margin-bottom: 0;
  }
}
</style>
