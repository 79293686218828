<template>
  <div
    class="scroll-to-top"
    id="scrollTop"
    @click="scrollTop"
    :class="{ visible: isVisible }"
  >
    <i class="bi bi-arrow-up-short"></i>
  </div>
</template>

<script>
export default {
  name: 'ScrollTop',
  data() {
    return {
      isVisible: false,
    };
  },
  created() {
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      window.addEventListener('scroll', () => {
        const el = document.getElementById('scrollTop');
        if (el) {
          const position = window.scrollY;
          if (position >= 900) this.isVisible = true;
          else if (
            window.innerHeight + window.pageYOffset >=
            document.body.offsetHeight
          )
            this.isVisible = true;
          else this.isVisible = false;
        }
      });
    },
    scrollTop() {
      window.scrollTo(0, 0);
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.scroll-to-top {
  position: fixed;
  bottom: 2%;
  right: 1%;
  cursor: pointer;
  opacity: 0;
  transition: $trs-default;
  z-index: 10;
  background-color: $primary;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 2px;
  box-shadow: $shadow-default;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: $light;
    font-size: 1.8rem;
  }
  &.visible {
    opacity: 1;
  }
}

@include bp-down(lg) {
  .scroll-to-top {
    right: 3%;
  }
}
</style>
