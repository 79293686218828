<template>
  <header class="header container-fluid fixed-top slide-down-d0">
    <nav class="navbar-container">
      <router-link :to="{ name: 'Home' }" class="navbar-logo">
        <img
          src="@/assets/img/logo-mobile.png"
          class="navbar-brand"
          alt="Alison Vandromme Logo"
          width="80"
          height="53"
        />
      </router-link>
      <ul class="navbar-menu" :class="{ active: open }" @click="open = false">
        <li class="navbar-item">
          <router-link :to="{ name: 'Home' }"> Home </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'About' }"> About </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'Projects' }"> Projects </router-link>
        </li>
        <li class="navbar-item">
          <router-link :to="{ name: 'Contact' }"> Contact </router-link>
        </li>
        <li class="navbar-item">
          <a href="#" class="app-btn" @click="openResume">Resume </a>
        </li>
      </ul>
      <div class="wrapper-menu" @click="open = !open" :class="{ open: open }">
        <div class="line-menu half start"></div>
        <div class="line-menu"></div>
        <div class="line-menu half end"></div>
      </div>
    </nav>
  </header>
</template>

<script>
import Api from '@/utils/services/Api';

export default {
  name: 'Navbar',
  data() {
    return {
      open: false,
      cvUrl: '',
    };
  },
  created() {
    this.cvUrl = Api.getUrl('files', 'AlisonVandromme-CV(en).pdf');
  },
  methods: {
    openResume() {
      window.open(this.cvUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: $sp $sp-3x;
  background-color: $darker;
}

.navbar-brand {
  width: 4rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.wrapper-menu {
  display: none;
}

.bar {
  display: block;
  width: 1.25rem;
  height: 3px;
  margin: 0.25rem auto;
  transition: $trs-default;
  background-color: $dark;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
}

.navbar-item {
  margin-left: 2rem;
  cursor: pointer !important;
  a {
    font-family: $accent-font;
    font-size: 1rem;
    transition: $trs-default;
  }
  a:hover,
  .router-link-active,
  .router-link-exact-active {
    color: $primary;
  }
}

@include bp-down(md) {
  .header {
    padding: 0.5rem 1rem;
    background-color: $darker;
  }
  .navbar-menu {
    position: fixed;
    right: -100%;
    top: 4rem;
    flex-direction: column;
    background-color: $darker;
    width: 100%;
    height: 100%;
    border-radius: 0rem;
    text-align: center;
    transition: 0.3s;
    z-index: 1000;
    box-shadow: $shadow-bottom;
    padding-bottom: $sp-2x;
  }
  .navbar-menu.active {
    right: 0;
    justify-content: flex-start;
    padding-top: 3rem;
  }
  .navbar-item {
    margin: 1.5rem 0;
  }
  .wrapper-menu {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;
  }

  .wrapper-menu.open {
    transform: rotate(-45deg);
  }

  .line-menu {
    background-color: $primary;
    border-radius: 5px;
    width: 100%;
    height: 6px;
  }

  .line-menu.half {
    width: 50%;
  }

  .line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
  }

  .open .line-menu.start {
    transform: rotate(-90deg) translateX(3px);
  }

  .line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
  }

  .open .line-menu.end {
    transform: rotate(-90deg) translateX(-3px);
  }
}
</style>
