<template>
  <AppSection title="Recent projects" id="featured" v-if="!isLoading">
    <AppFeatured side="right" :project="fuelguide" id="project1" />
    <AppFeatured side="left" :project="irtc" id="project2" />
    <div class="spacer-3x"></div>
  </AppSection>
</template>

<script>

import AppFeatured from '@/components/app/Featured';
import AppSection from '@/components/app/Section';

import Api from '@/utils/services/Api';
export default {
  name: 'Featured',
  components: {
    AppSection,
    AppFeatured,
  },
  data() {
    return {
      fuelguide: {},
      irtc: {},
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true;
    this.fuelguide = await Api.getOne('fuelguide-v3');
    this.irtc = await Api.getOne('irtc-decision-tool');
    this.isLoading = false;
  }
};
</script>
