export default class Session {
  static setFromRoute(name, path) {
    sessionStorage.setItem('from_name', name);
    sessionStorage.setItem('from_path', path);
  }

  static getFromRoute() {
    return {
      name: sessionStorage.getItem('from_name'),
      path: sessionStorage.getItem('from_path'),
    };
  }
}
