<template>
  <div
    class="row mb-4 featured"
    :class="[`featured-${side}`, { active: isActive }]"
    :id="id"
    v-if="project"
  >
    <div class="col-12 col-xl-6 mb-4 right-col-desk" v-if="side === 'right'">
      <img class="img-fluid" :src="project.thumbnail ? project.thumbnail : project.images[0]" :alt="project.title" />
    </div>
    <div class="col-12 col-xl-6">
      <router-link :to="{ name: 'ProjectDetails', params: { id: project.id } }"
        ><h3 class="hovered-txt">{{ project.title }}</h3></router-link
      >
      <div class="featured-description" :class="{ active: isActive }">
        <p v-for="featured in project.featured" :key="featured">
          {{ featured }}
        </p>
        <div class="more-link">
          <router-link
            class="hovered-link"
            :to="{ name: 'ProjectDetails', params: { id: project.id } }"
            >See details</router-link
          >
          <i class="bi bi-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6" v-if="side === 'left'">
      <img class="img-fluid" :src="project.thumbnail ? project.thumbnail : project.images[0]" :alt="project.title" />
    </div>
    <div class="col-12 col-xl-6 right-col-mob" v-if="side === 'right'">
      <img class="img-fluid" :src="project.thumbnail ? project.thumbnail : project.images[0]" :alt="project.title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feat',
  props: ['side', 'id', 'project'],
  data() {
    return {
      isActive: false,
    };
  },
  created() {
    window.addEventListener('scroll', () => {
      const el = document.getElementById(this.id);
      if (el) {
        const position = el.getBoundingClientRect();
        if (position.top >= 150 && position.top <= 500) this.isActive = true;
        else this.isActive = false;
      }
    });
  },
};
</script>

<style lang="scss">
.more-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  i {
    margin-left: 0.5rem;
    color: $secondary-50;
    transition: $trs-default;
  }
  &:hover {
    i {
      color: $primary;
    }
  }
}

.right-col-mob {
  display: none;
}

@include bp-down(xl) {
  .right-col-mob {
    display: flex;
  }
  .right-col-desk {
    display: none;
  }
}
</style>
