<template>
  <div class="side side-right slide-up-d0">
    <div class="side-content">
      <a href="#" class="email-link">contact@alisonvandromme.com</a>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email'
};
</script>

